<template>
  <div>
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลการย้าย สายการสอน ผู้ประสงค์ขอย้ายเข้า"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row>
            <v-col cols="12" md="6" class="text-right">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
          
          </v-row>
        </v-card>
        <v-data-table
          color="success"
          :loading="loading"
          :headers="headers"
          :items="transference_locations"
          :search="search"
        >
          <template v-slot:item="{ item, index }">
            <tr>
              <td class="text-center">{{ index + 1 }}</td>
              <!--        <td class="text-center">{{ item.tlid_ref }}</td> -->
              <td class="text-center">{{ item.tlid_card }}</td>
              <td class="text-center">{{ item.frist_name }}</td>
              <td class="text-center">{{ item.last_name }}</td>
              <td class="text-center">{{ item.college_name }}</td>
              <td class="text-center">{{ item.name_branch }}</td>
              <td class="text-center">              
                <v-chip color="success" v-if="item.tlid_branch===item.conditionidbranch">
                  ตรงเงือนไขรับย้าย
                </v-chip>
        
                <v-chip color="red" dark v-else> ไม่ตรงเงือนไขรับย้าย </v-chip>
              </td>
              <td class="text-center">{{ item.time_ss }}</td>
              <td class="text-center">{{ item.year_ss }}</td>
              <td class="text-center">{{ item.age_app_time }}</td>
              <td class="text-center">
                <div v-if="item.documentsLinkfile">
                  <v-btn
                    large
                    color="info"
                    text
                    @click="viewdocumentsLinkfile(item.documentsLinkfile)"
                    ><v-icon large>mdi-printer</v-icon></v-btn
                  >
                </div>
                <div v-else>
                  ไม่พบไฟล์แนบ <br />
                  <span class="red--text"
                    >ให้ดำเนินการไม่เห็นควร</span
                  >
                </div>
              </td>
              <td class="text-center">{{ item.date_time }}</td>
              <td class="text-center">
                <v-chip
                  v-if="item.tlMcommittee === 'approp'"
                  color="green"
                  dark
                  :href="'/HRvecfiles/' + item.tlMdocumentsLinkfile"
                  target="_blank"
                >
                  <v-icon>mdi-printer</v-icon>
                  เห็นควร
                </v-chip>
                <v-chip
                  v-else-if="item.tlMcommittee === 'inapprop'"
                  color="warning"
                  dark
                  :href="'/HRvecfiles/' + item.tlMdocumentsLinkfile"
                  target="_blank"
                  ><v-icon>mdi-printer</v-icon>
                  ไม่เห็นควร
                </v-chip>
                <v-icon
                  v-else
                  color="yellow"
                  large
                  @click.stop="select_idPosition(item.id_tfl)"
                  >mdi-credit-card-plus</v-icon
                >
              </td>
              <td class="text-center">
                <div v-if="item.tlMdocumentsLinkfile">
                  <v-btn
                    text
                    rounded
                    color="green"
                    large
                    :href="'/HRvecfiles/' + item.tlMdocumentsLinkfile"
                    target="_blank"
                    ><v-icon>mdi-printer</v-icon></v-btn
                  >
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    ไม่ได้แนบไฟล์ ผลการพิจารณา/วาระประชุม
                  </v-chip>
                </div>
              </td>
              <td class="text-center">
                <div v-if="item.tlMdocumentsLinkfile">
                  <v-chip
                    color="red"
                    dark
                    v-if="item.tlstatus_transfer === 'cc'"
                    >รอการยกเลิก</v-chip
                  >
                  <v-btn
                    v-else
                    color="warning"
                    @click="cancelPDFSubmit(item.id_tfl)"
                  >
                    <v-icon color="red" class="pr-1">mdi-close</v-icon>
                    ยกเลิกแนบไฟล์</v-btn
                  >
                </div>
                <div v-else>
                  <v-chip dark color="warning">
                    <v-icon>mdi-information</v-icon> ไม่ได้แนบไฟล์
                  </v-chip>
                </div>
              </td>
            </tr>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model edittransference_locationdialog -->
      <v-layout row justify-center>
        <v-dialog
          v-model="edittransference_locationdialog"
          persistent
          max-width="80%"
        >
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="แก้ไขข้อมูลผู้ใช้งานระบบ"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="edittransference_locationform" lazy-validation>
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      {{ edittransference_location.id_card }}
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="Password"
                        v-model="edittransference_location.p_word"
                        type="password"
                      ></v-text-field>
                    </v-flex>
                    <v-flex md6>
                      <v-text-field
                        label="Confirm Password"
                        v-model="
                          edittransference_location.transference_location_confirmpassword
                        "
                        type="password"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
                <small>* จำเป็น</small>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                large
                @click.stop="edittransference_locationdialog = false"
                rounded
              >
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="warning"
                @click.stop="edittransference_locationSubmit()"
                rounded
              >
                <v-icon dark>mdi-pencil</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model positiondialog -->
      <v-layout row justify-center>
        <v-dialog v-model="positiondialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="yellow"
              icon="mdi-clipboard-text"
              title="ความเห็นคณะกรรมการสถานศึกษา"
              class="px-5 py-3 text_google"
            ></base-material-card>
            <v-card-text>
              <v-form ref="updatepositionform" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <h4>
                        รหัสอ้างอิง :
                        {{ transference_locations_id_ref.tlid_ref }}
                      </h4>
                      รหัสบัตรประชาชน :
                      {{ transference_locations_id_ref.tlid_card }}
                      <h4>
                        ชื่อ-นามสกุล :
                        {{ transference_locations_id_ref.title_s
                        }}{{ transference_locations_id_ref.frist_name }}
                        {{ transference_locations_id_ref.last_name }}
                        สถานศึกษาปัจจุบัน :
                        {{ transference_locations_id_ref.college_code_now }}
                        {{ transference_locations_id_ref.college_name_now }}
                        <br />
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_locations_id_ref.id_position_now }}
                        <br />
                        วุฒิการศึกษา :
                        {{ transference_locations_id_ref.ed_abb }}
                        {{ transference_locations_id_ref.name_branch }}
                      </h4>
                    </v-col>
                    <!--  <v-col cols="12" md="12">
                      <v-card elevation="2" class="pa-2">
                        <h3>
                          เหตุผลในการย้าย :
                        </h3>
                        <h4>
                          {{
                            transference_location_location_s.manage_reason_detail
                          }}
                        </h4>
                      </v-card>
                    </v-col> -->
                  </v-row>
                  <v-col cols="12" md="12">
                    <v-card elevation="2" class="pa-2">
                      <v-flex md12>
                        <v-col cols="12" md="12">
                          <v-alert
                            class="mx-auto text-center pa-2 ma-2"
                            border="top"
                            colored-border
                            type="info"
                            elevation="2"
                          >
                            <h2>ความคิดเห็น</h2>
                            <h2>
                              <v-radio-group
                                v-model="
                                  transference_location_location_s.tlMcommittee
                                "
                                row
                                required
                                :rules="[v => !!v || '']"
                                align="center"
                              >
                                <v-radio value="approp">
                                  <template v-slot:label>
                                    <div>
                                      <strong class="primary--text"
                                        >เห็นควร</strong
                                      >
                                    </div>
                                  </template>
                                </v-radio>
                                <v-radio value="inapprop">
                                  <template v-slot:label>
                                    <div>
                                      <strong class="warning--text"
                                        >ไม่เห็นควร</strong
                                      >
                                    </div>
                                  </template>
                                </v-radio>
                              </v-radio-group>
                            </h2>
                          </v-alert>
                        </v-col>
                        <v-col cols="12" md="12">
                          แนบไฟล์ผลการพิจารณา/วาระประชุม
                          <v-file-input
                            v-model="documentsLinkfiles"
                            accept=".pdf"
                            name="documentsLinkfiles"
                            color="deep-purple accent-4"
                            counter
                            label="ไฟล์ .pdf"
                            placeholder="เลือกแนบไฟล์ PDF"
                            prepend-icon="mdi-paperclip"
                            outlined
                            rounded
                            :rules="[v => !!v || '']"
                            :show-size="1000"
                          >
                            <template v-slot:selection="{ index, text }">
                              <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                              >
                                {{ text }}
                              </v-chip>

                              <span
                                v-else-if="index === 2"
                                class="text-overline grey--text text--darken-3 mx-2"
                              >
                                +{{ files.length - 2 }} File(s)
                              </span>
                            </template>
                          </v-file-input>
                        </v-col>
                      </v-flex>
                    </v-card>
                  </v-col>
                </v-container>
              </v-form>
              <v-row>
                <v-col cols="12" md="12" class="text-center">
                  <h3 class="red--text">
                    * เมื่อกดบันทึกแล้วไม่สามารถแก้ไขเปลี่ยนแปลงได้
                    กรุณาตรวจสอบให้ให้ถูกต้อง
                  </h3>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="positiondialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>
              <v-btn
                large
                color="green"
                @click.stop="updatepositionSubmit()"
                rounded
              >
                <v-icon dark>mdi-content-save</v-icon>&nbsp;บันทึก
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>

      <!-- V-model pdfdocumentsLinkfileDialog -->
      <v-layout row justify-center>
        <v-dialog v-model="pdfdocumentsLinkfileDialog" max-width="80%">
          <v-card class="" elevation="2">
            <embed
              :src="'/HRvecfiles/' + pdf_files"
              width="100%"
              height="700px"
            />
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>
          {{ snackbar.text }}
        </v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: false,
      ApiKey: "HRvec2021",
      valid: true,
      edittransference_locationdialog: false,
      positiondialog: false,
      times_select: "1",
      documentsLinkfiles: null,
      years_select: "2565",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      time_ss: [1, 2],
      year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      transference_locations: [],
      edittransference_location: {},
      search: "",
      pagination: {},
      headers: [
        { text: "#", align: "center", value: "index" },
        /*         { text: "อ้างอิง", align: "center", value: "tid_ref" }, */
        { text: "รหัสบัตรประชาชน", align: "center", value: "id_card" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },
        { text: "สังกัด", align: "center", value: "college_name" },
        { text: "สาขาวิชา", align: "center", value: "name_branch" },
        { text: "เงือนไขรับย้าย", align: "center", value: "conditionidbranch" },
        { text: "ครั้งที่", align: "center", value: "time_ss" },
        { text: "ปีที่", align: "center", value: "year_ss" },
        { text: "อายุงาน ณ ปัจจุบัน", align: "center", value: "age_app_time" },
        { text: "เอกสารคำร้อง", align: "center", value: "documentsLinkfile" },
        { text: "วันที่ทำรายการ", align: "center", value: "date_time" },
        {
          text: "ผลการพิจารณา",
          align: "center",
          value: "tlMcommittee"
        },
        { text: "เอกสารแนบ", align: "center", value: "tlMdocumentsLinkfile" },
        { text: "แจ้งยกเลิก", align: "center", value: "cancelPDF" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      transference_locationstatus: [],
      userstatus: {},
      commentDrdialog: false,
      transference_locations_id_ref: [],
      regions: [],
      periods: [],
      period_enable: "1",
      updatecomment: {},
      branch_s: [],
      branchs_id_sub: [],
      user: [],
      transference_location_location_s: [],
      documentCheck: [],
      data_syslog: {},
      transference_locationsCc: [],
      pdf_files: [],
      pdfdocumentsLinkfileDialog: false
    };
  },
  async mounted() {
    let result;
    let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
    result = await this.$http.post("admin.php", {
      user_name: userSession.user_name,
      provice: "check",
      ApiKey: "HRvec2021"
    });
    this.user = result.data;
    await this.periodQuery();
  },
  methods: {
    async viewdocumentsLinkfile(documentsLinkfile) {
      this.pdf_files = documentsLinkfile;
      this.pdfdocumentsLinkfileDialog = true;
    },

    async cancelPDFSubmit(id_tfl) {
      let result = await this.$http.post("transference_location.php", {
        ApiKey: this.ApiKey,
        id_tfl: id_tfl
      });
   
      this.transference_locationsCc = result.data;
      let text =
        this.transference_locationsCc.frist_name +
        " " +
        this.transference_locationsCc.last_name;

      Swal.fire({
        title: "คุณต้องการเปลี่ยนสถานะ?",
        text: text,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "ยกเลิกไฟล์แนบความคิดเห็นปลายทาง",
        cancelButtonText: "ยกเลิก"
      }).then(async result => {
        if (result.isConfirmed) {
          this.transference_locationsCc.ApiKey = this.ApiKey;
          this.transference_locationsCc.tlstatus_transfer = "cc";

          let resultupdate = await this.$http.post(
            "transference_location.update.movein.php",
            this.transference_locationsCc
          );
          
          await this.transference_locationQueryAll();
        }
      });
    },

    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period_college.php", {
        ApiKey: this.ApiKey,
        period_college_enable: "1",
        period_college_type: "discuss_teacher"
      });
      this.periods = result_period.data;
      if (this.periods.period_college_enable != "1") {
        Swal.fire({
          icon: "error",
          title: "ยังไม่มีกำหนดการ",
          allowOutsideClick: false,
          allowEscapeKey: false
        }).then(async result => {
          if (result.isConfirmed) {
            this.$router.push("/college");
          }
        });
      } else {
        await this.transference_locationQueryAll();
      }
    },

    async comment_idPosition(tid_ref) {
      if (this.period_college_file.period_college_enfile == "1") {
        let result_con = await this.$http.post("transference_location.php", {
          ApiKey: this.ApiKey,
          id_ref: tid_ref
        });
        this.transference_locations_id_ref = result_con.data;
        this.documentsLinkfiles = null;
        this.commentDrdialog = true;
      } else {
        this.snackbar.icon = "mdi-close-network";
        this.snackbar.color = "red";
        this.snackbar.text = "ขณะนี้ได้ปิดระบบรับรายงานข้อมูล";
        this.snackbar.show = true;
      }
    },

    async transference_locationQueryAll() {
      this.loading = true;
      let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
      let result = await this.$http
        .post("transference_location.php", {
          ApiKey: this.ApiKey,
          college_code: userSession.user_name,
          time_s: this.periods.period_college_times,
          year_s: this.periods.period_college_year,
          college_code_Newcheck: "Ok"
        })
        .finally(() => (this.loading = false));
      this.transference_locations = result.data;

      
    },

    async select_idPosition(id_tfl) {
      let result_con = await this.$http.post("transference_location.php", {
        ApiKey: this.ApiKey,
        id_tfl: id_tfl
      });
      this.transference_locations_id_ref = result_con.data;
      this.updatepositions = {};
      this.positiondialog = true;
      ;

    },

    /// updatepositionSubmit
    async updatepositionSubmit() {
      await this.periodQuery();
      let result = "";
      let uploaded = null;
      if (this.$refs.updatepositionform.validate()) {
        await this.sweetAlertLoading();
        this.updatepositions.ApiKey = this.ApiKey;
        let userSession = JSON.parse(sessionStorage.getItem("user"));
        this.updatepositions.id_tfl = this.transference_locations_id_ref.id_tfl;
        this.updatepositions.tlMcommittee = this.transference_location_location_s.tlMcommittee;

        if (this.documentsLinkfiles != "") {
          let formData = new FormData();
          let filename =
            this.transference_locations_id_ref.tlid_ref +
            userSession.user_name +
            "collegeAssessmentMovein.pdf";
          formData.append("ApiKey", this.ApiKey);
          formData.append("file", this.documentsLinkfiles);
          formData.append("filename", "../HRvecfiles/" + filename);

          result = await this.$http.post("uploadfile.php", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          });
          if (result.data.status == true) {
            this.updatepositions.tlMdocumentsLinkfile = filename;
          }
          uploaded = true;
        } else {
          uploaded = false;
        }
        let result = await this.$http.post(
          "transference_location.update.movein.php",
          this.updatepositions
        );

        if (result.data.status || upload) {
          Swal.fire({
            icon: "success",
            title: "ปรับปรุงข้อมูลเรียบร้อย",
            showConfirmButton: false,
            timer: 1500
          });
          let userSession = JSON.parse(sessionStorage.getItem("user")) || 0;
          this.data_syslog.ApiKey = this.ApiKey;
          this.data_syslog.user_account = userSession.user_name;
          this.data_syslog.event_log = "update";
          this.data_syslog.page_log = "transference_location.update.movein";
          this.data_syslog.table_log = "transference_location.update.movein";
          this.data_syslog.detail_log = this.transference_locations_id_ref.tid_ref;
          this.data_syslog.date_times = this.date_today_log;
          await this.$http.post("data_syslog.insert.php", this.data_syslog);
        } else {
          if (!uploaded) {
            Swal.fire({
              icon: "warning",
              title: "ไฟล์ที่แนบไม่ถูกต้อง",
              showConfirmButton: false,
              timer: 1500
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "บันทึกข้อมูลผิดพลาด",
              showConfirmButton: false,
              timer: 1500
            });
          }
        }
        this.positiondialog = false;
        this.transference_locationQueryAll();
      }
      Swal.close();
    },

    async sweetAlertLoading() {
      Swal.fire({
        title: "Please Wait !",
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
        },
        onBeforeOpen: () => {
          Swal.showLoading();
        },
        onAfterClose: () => {
          Swal.hideLoading();
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false
      });
    }
    /*  countStringFile(documentFile){
          let result
          let fileKeep = documentFile.length      
          if(fileKeep >= 5){
            result = '1'
          }else{
            result = '0'
          }
          return result     
      }, */
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;

      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    time_stamp() {
      let time = Date.now();
      return time;
    },
    date_today() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0");
      let yyyy = today.getFullYear() + 543;
      today = dd + "/" + mm + "/" + yyyy;
      return today;
    },
    color() {
      return "#573005";
    },
    date_today_log() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear() + 543;
      let time =
        today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      today = dd + "/" + mm + "/" + yyyy + "/" + time;
      return today;
    }
  }
};
</script>
